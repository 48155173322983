<template>
  <footer class="mt-5">
    <div class="py-5">
        <ul class="navbar-nav flex-row justify-content-center align-items-center list-inline">
            <li class="nav-item me-3 inline-list-item"><span class="copyright">&copy; 2023 Prolitec, Inc. All rights reserved. </span></li>
            <SignInOut></SignInOut>
            <li v-if="isUserSignedIn" class="inline-list-item me-3 nav-item">
                <router-link v-if="$route.matched.some(({ name }) => name != 'Dashboard')" to="/dashboard" class="link link-primary ms-2">Dashboard</router-link>
            </li>
            <li class="inline-list-item me-3 nav-item"><a href="mailto:vsesupport@prolitec.com">VSE Application Support</a></li>
        </ul>
    </div>
  </footer>
</template>
<script>
import { mapGetters } from 'vuex';
import SignInOut from '../components/SignInOut.vue';

export default {
    name: 'Footer',
    components: {
        SignInOut
    },
    data() {
        return {
            signin: 'https://microsoft.com',
        }
    },
    computed: {
        // account() {
        //     return this.$store.state.account;
        // },
        ...mapGetters({
            getCurrentUserInRole: 'getCurrentUserInRole',
            account: 'getCurrentUser',
            isUserSignedIn: 'isSignedInUser'
        }),
    },
    async created() {
    // this.$msalInstance = new PublicClientApplication(
    //   this.$store.state.msalConfig,
    // );
  },
    mounted () {
        // const accounts = this.$msalInstance.getAllAccounts();
        // if (accounts.length == 0) {
        // return;
        // }
        // this.$store.commit('setAccount', accounts[0]);
        // this.$emitter.emit('login', this.account);
    },
    methods: {
        // async SignIn() {
        // console.log('get msal instance');
        // console.log(this.$msalInstance);
        // console.log('get msal instance complete');
        // await this.$msalInstance.loginPopup({scopes: ["User.ReadWrite"]})
        //     .then(() => {
        //         const myAccounts = this.$msalInstance.getAllAccounts();
        //         this.$store.commit('setAccount', myAccounts[0]);
        //         console.log('footer component...sign in');
        //         console.log(this.$store.state.account);
        //         this.$emitter.emit('login', this.$store.state.account);
        //     })
        //     .catch(error => {
        //         console.error(`error during authentication: ${error}`);
        //     });
        // },
        // async SignOut() {
        //     console.log('Sign Out');
        //     await this.$msalInstance
        //     .logoutPopup({
        //         account: this.account,
        //         postLogoutRedirectUri: "https://localhost:8080/login/loggedout",
        //         mainWindowRedirectUri: "https://localhost:8080/login/loggedout",
        //     })
        //     .then(() => {
        //     this.$emitter.emit('logout', 'logging out');
        //     })
        //     .catch(error => {
        //         console.error(error);
        //     });
        // },
    },
}
</script>
