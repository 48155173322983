// import dependency to handle HTTP request to our back end
/* eslint-disable no-unused-vars */
import { createStore, createLogger } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import { loader } from './modules/loader';
import uiModule  from './modules/ui.js';
import fragrancesExtendedModule from './modules/fragrances-extended.js';
import adjectivesModule from './modules/adjectives.js';
import providersModule from './modules/providers.js';
import usersModule from './modules/users.js';
import questionsModule from './modules/questions.js';
import invitesModule from './modules/invites.js';
import quizResultsModule from './modules/quiz.js';


const store = createStore({
  state() {
    return {
      userAccessToken: '',
      apiAccessToken: '',
      account: '',
      dataRefreshTimestamp: null,
    };
  },
  modules:
  {
    fragrancesExtendedModule, adjectivesModule, providersModule, usersModule, questionsModule, quizResultsModule, invitesModule, loader, uiModule
  },
  getters: 
  {
    getCurrentUserInRole: (state) => (tgtRole) => {
        if (state.account && state.account.idTokenClaims && state.account.idTokenClaims.roles) {
            //console.log('State.Account: ');
            //console.log(state.account);
            return state.account.idTokenClaims.roles.includes(tgtRole) ? true : false;
        } else {
            //console.log('State.Account not found? ');
            //console.log(state.account);
            return false;
        }
    },
    isSignedInUser(state) {
        return state.account ? true : false;
    },
    getCurrentUser(state) {
        return state.account;
    }
  },
  mutations : {
    setUserAccessToken(state, token){
      state.userAccessToken = token;
    },
    setApiAccessToken(state, token){
      state.apiAccessToken = token;
    },
    setAccount(state, account) {
      state.account = account;
    },
    clearAccount(state) {
      state.account = null;
    }
  }, 
  actions : {
    async init({ dispatch }) {
      // await dispatch('login')
    },
    async resetStorage({ dispatch }) {
        localStorage.removeItem("Prolitec-VSE");
    }
  },
  computed: {
  },
  plugins: [createPersistedState({
    key: "Prolitec-VSE",
    paths: ["fragrancesExtendedModule", "adjectivesModule", "providersModule", "usersModule", "questionsModule", "quizResultsModule", "invitesModule", "uiModule"]
  })],
})

export default store;
/* eslint-enable no-unused-vars */
