import axios from 'axios'; 

export default {
  namespaced: true,
  state() {
    return {
        axiosQuizResultListConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'lH6l1fxnALbC5aTIluBRWirEivA9hr1vqCUCM38UHTwK0Yu2oYQxEw==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        axiosAddVseQuizResultConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'zMXnV3vu7moKaNtY-Nhs5YU5CGFbel8xbAsEjWsuH98FAzFukQVHYA==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        axiosUpdateVseQuizResultConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'mSapULQObGKigeHoA8RiqnfwDfQGqU61oWJSdhd5gDNN6UTd223nqw==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        axiosDeleteVseQuizResultConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'HePeQvZNbpiePjWzDJ3ejoCPq4WbId5RojPlN1Ox9c0fx0XEulU0FQ==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        quizResults: [],
        isQuizResultListCachable: true
    };
  },
  getters: {
      getQuizResults(state) {
        console.log('Accessing getQuizResults getter...');
        if (state.quizResults != null && state.quizResults.length > 0) {
            console.log('State detected in getter. Length: ' + state.quizResults.length);
        }
        return state.quizResults;
      },
      getQuizResultsByProviderName: (state) => (providerName) => {
        if (null != state.quizResults) {
            let _inviteResults = state.quizResults.filter(result => result.invite != null && result.invite.provider != null && result.invite.provider === providerName);
            let _providerResults = state.quizResults.filter(result => result.project != null && result.project.provider != null && result.project.provider === providerName);
            let _results = _inviteResults.concat(_providerResults);
            return _results != null && _results.length > 0 ? _results : null;
        } else {
            console.log(state.quizResults);
            return null;
        }
      },
      getQuizResultsBySalespersonName: (state) => (salespersonEmail) => {
        if (null != state.quizResults) {
            let _inviteResults = state.quizResults.filter(result => result.invite != null && result.invite.salesperson != null && result.invite.salesperson === salespersonEmail);
            let _projectResults = state.quizResults.filter(result => result.project != null && result.project.salesperson != null && result.project.salesperson === salespersonEmail);
            let _results = _inviteResults.concat(_projectResults);
            return _results != null && _results.length > 0 ? _results : null;
        } else {
            console.log(state.quizResults);
            return null;
        }
      },
      totalCount: (state) => {
        if (state.quizResults == null) {
            return 0;
        } else {
            return state.quizResults.length;
        }   
      },
      countByProvider: (state) => (provider) => {
        if (null != state.quizResults) {
            console.log(provider);
            let _inviteResults = state.quizResults.filter(result => result.invite != null && result.invite.provider != null && result.invite.provider === provider.id);
            let _providerResults = state.quizResults.filter(result => result.project != null && result.project.provider != null && result.project.provider === provider.name);
            let _results = _inviteResults.concat(_providerResults);
            console.log(_results);
            return _results != null && _results.length > 0 ? _results.length : 0;
        } else {
            console.log(state.quizResults);
            return 0;
        }
    },
    countBySalesperson: (state) => (salespersonEmail) => {
        if (null != state.quizResults) {
            let _inviteResults = state.quizResults.filter(result => result.invite != null && result.invite.salesperson != null && result.invite.salesperson === salespersonEmail);
            let _projectResults = state.quizResults.filter(result => result.project != null && result.project.salesperson != null && result.project.salesperson === salespersonEmail);
            let _results = _inviteResults.concat(_projectResults);
            return _results != null && _results.length > 0 ? _results.length : 0;
        } else {
            console.log(state.quizResults);
            return 0;
        }
    },
      completedQuizCount: (state) => {
        if (state.quizResults == null) {
            return 0;
        } else {
            return state.quizResults.filter(quiz => quiz.isQuizCompleted == true).length;
        } 
      }
  },
  mutations: {
    SET_QUIZ_RESULTS(state, quizResults) {
        console.log('Set quizResults mutation...');
        console.log(quizResults);
        state.quizResults = quizResults;
    }, 
    ADD_QUIZ_RESULT(state, quizResult) {
        console.log('Add quizResult mutation...');
        console.log(quizResult);
        state.quizResults.push(quizResult);  
    },
    UPDATE_QUIZ_RESULT(state, quizResult) {
        console.log('Update quizResult mutation...');
        console.log(quizResult);
        const index = state.quizResults.findIndex(x => x.id === quizResult.id);
        if (index !== undefined) {
            state.quizResults[index].name = quizResult.name;
        }
    },
    DELETE_QUIZ_RESULT(state, quizResultId) {
        console.log('Delete quizResult mutation...');
        console.log(quizResultId);
        const index = state.quizResults.findIndex(x => x.id === quizResultId);
        if (index !== undefined) {
            state.quizResults.splice(index, 1);
        } 
    },
  },
  actions: {
    async fetchQuizResults({ commit, state }) {
        try {
            //commit('contentLoading', true);
            return await axios.get('https://prolitecdataservices.azurewebsites.net/api/vseListQuizResults',state.axiosQuizResultListConfig)
            .then((response) => {
                if (response.status == 200) {
                console.log(response.data);
                console.log(response.data.items);
                commit('SET_QUIZ_RESULTS', response.data.items);
                return response.data.items;
                }
            })
            .catch(error => {
                console.error('API Error.',error);
                });
                //new Error(response.message || 'Failed to fetch requests.'));
            } finally {
            //commit('contentLoading', false);
            }    
    },
    async addQuizResult({ commit, state }, quizResult) {
        console.log('quizResult:');
        console.log(quizResult);
        try {
            // //commit('contentLoading', true);
            // var postVseQuizResultData = {
            //     'invite': quizResult.invite,
            //     'isQuizStarted': true,
            //     'quizStartDate': quizResult.quizStartDate,
            //     'isQuizCompleted': false,
            //     'questions': [],
            //     'recommendedFragrances': []
            //   };
            return await axios.post('https://prolitecdataservices.azurewebsites.net/api/vseCreateQuizResult', quizResult, state.axiosAddVseQuizResultConfig)
            .then((response) => {
              if (response.status == 200) {
                console.log(response.data);
                quizResult.id = response.data.id;
                commit('ADD_QUIZ_RESULT', quizResult);
                return quizResult;
              }
            })
        .catch(error => {
            console.error('API Error.',error);
            });
              //new Error(response.message || 'Failed to fetch requests.'));
        } finally {
            //commit('contentLoading', false);
        }
    },
    async updateQuizResult({ commit, state }, quizResult) {
        try {
            //commit('contentLoading', true);
            // var postVseQuizResultData = {
            //     'id': quizResult.id,
            //     'name': quizResult.name
            //   };
              console.log(state.axiosUpdateVseQuizResultConfig);
            return await axios.post('https://prolitecdataservices.azurewebsites.net/api/vseUpdateQuizResult', quizResult, state.axiosUpdateVseQuizResultConfig)
            .then((response) => {
              if (response.status == 200) {
                console.log(response.data);
                commit('UPDATE_QUIZ_RESULT', quizResult);
                return response.data;
              }
            })
        .catch(error => {
            console.error('API Error.',error);
            });
              //new Error(response.message || 'Failed to fetch requests.'));
        } finally {
            //commit('contentLoading', false);
        }
    },
    async deleteQuizResult({ commit, state }, quizResultId) {
        try {
            //commit('contentLoading', true);
            var vseQuizResultData = {
                'id': quizResultId
              };
              console.log(vseQuizResultData);
              console.log(state.axiosDeleteVseQuizResultConfig);
            return await axios.delete('https://prolitecdataservices.azurewebsites.net/api/vseDeleteQuizResult', { headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'HePeQvZNbpiePjWzDJ3ejoCPq4WbId5RojPlN1Ox9c0fx0XEulU0FQ==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }, data: { id: quizResultId } })
            .then((response) => {
              if (response.status == 200) {
                console.log(response.data);
                commit('DELETE_QUIZ_RESULT', quizResultId);
                return response.status;
              }
            })
        .catch(error => {
            console.error('API Error.',error);
            });
              //new Error(response.message || 'Failed to fetch requests.'));
        } finally {
            //commit('contentLoading', false);
        }
    }
  }
};