<template>
    <section class="container col-md-3 col-md-offset-3 col-sm-6">
      <h1>The Virtual Scent Expert</h1>
      <div class="my-4">Welcome to Prolitec’s fragrance recommendation tool – 
          here to help you navigate the exciting, wide world of scent options. 
          Click the sign in button below to get started.</div>
        <a class="btn btn-primary"
        v-if="!user"
        @click="loginUser"
        rel="noopener noreferrer"
    >Sign In<span class="ms-1"><BIconBoxArrowInRight /></span></a>
    <span v-else>
        <a class="btn btn-primary" @click="shallowLogout" href="#" rel="noopener noreferrer">Sign Out <span class="ms-1"><BIconBoxArrowRight /></span></a>
        
    </span>
    </section>
  </template>

<script>
import {mapGetters } from 'vuex';
import auth from "../services/auth";
import { VUE_APP_MSAL_CLIENT_ID } from '../utils/constants.js';

export default {
    name: 'Login',
    components: {
    },
    data() {
        return {
            isSignInProcessing: false,
            signInErrorMsgVisible: false,
            signOutMsgVisible: false,
            signin: "https://microsoft.com",
            // User account object synced with MSAL getAccount()
            // Access token fetched via MSAL for calling Graph API
            accessToken: "",
            error: "",
        }
    },
    async created() {
        console.log('Component created...');
        // Basic setup of MSAL helper with client id, or give up
        if (VUE_APP_MSAL_CLIENT_ID) {
            auth.configure(VUE_APP_MSAL_CLIENT_ID, false);
            // Restore any cached or saved local user
            this.$store.commit('setAccount', auth.user());
            console.log(`configured ${auth.isConfigured()}`);
        } else { 
            this.error = "VUE_APP_MSAL_CLIENT_ID is not set, the app will not function! 😥";
            console.log(this.error);
        }
    },
    mounted() {},
    computed: {
        ...mapGetters ({
            user: 'getCurrentUser'
        })
    },
    methods: {
        // Update user from MSAL
        async loginUser() {
            console.log('MSAL login start...');
            try {
                await auth.login().then(() => {
                    this.updateUser();
                    // 11 Nov 22 - redirect to dashboard per H. Lane
                    this.$router.push('/dashboard');
                });
            } catch (err) {
                this.error = err.toString()
            }
        },
        updateUser() {
            this.$store.commit('setAccount', auth.user());
        },
        // Remove locally held user details, is same as logout
        shallowLogout() {
            this.$store.commit('clearAccount');
            auth.clearLocal();
            this.$router.push('/');
        },
        // Full logout local & server side
        fullLogout() {
            this.$store.commit('clearAccount');
            auth.logout();
        },
    }
}
</script>
