<template>
  <section v-if="getCurrentUserInRole('Task.Admin') | getCurrentUserInRole('Task.Manager')" id="UserList" class="container">
    <breadcrumbVue :items="crumbs"></breadcrumbVue>
    <div class="d-flex align-items-between">
        <div class="col">
            <h1>Users</h1>
            <p>View, edit, or delete user information by clicking on their name. 
                Add user by clicking the “add user” button. </p>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-4">
            <jumpNavigation></jumpNavigation>
        </div>
    </div>
    <div>
      <router-link to="/users/add" class="btn btn-primary">Add User</router-link>
    </div>
    <div v-if="isLoading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- User List -->
    <div v-else class="table-responsive">
        <table class="table table-striped">
        <thead>
            <tr>
            <th @click="sort('displayName')">Name</th>
            <th @click="sort('email')">Email</th>
            <th>Role</th>
            <th>Scent Provider</th>
            <th>Joined</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="user in sortedUsers" :key="user.id">
            <td><router-link v-if="user.id != null" :to="{ name: 'UserDetail', params: { userId: user.id }}" class="link link-primary">{{ user.firstName + ' ' + user.lastName }}</router-link></td>
            <td>{{ user.email }}</td>
            <td>{{ getDisplayRole(user.role) }}</td>
            <td><span v-if="user.provider">{{ getNameForProviderId(user.provider) }}</span></td>
            <td><span v-if="user.joined">{{ formatToShortDate(user.joined) }}</span></td>
            </tr>
        </tbody>
        </table>
    </div>
    <!-- ACTIONS --> 
  </section>
</template>

<script>
import breadcrumbVue from '../../components/breadcrumb.vue';
import jumpNavigation from '../../components/jump-navigation.vue';
import { formatToShortDate } from '../../utils/helpers.js';
import {mapGetters } from 'vuex';

export default {
  name: 'UserList',
  components: {
    breadcrumbVue,
    jumpNavigation,
  },
  data() {
    return {
        usersForCurrentRole: [],
        sortedUsers: [

        ],
        crumbs: [{
            name: 'Dashboard',
            url: '/dashboard'
                }, {
                    name: 'Users',
                    url: '/users'
        }],
        currentSort: '',
        currentSortDir: 'asc',
        isLoading: false,
        currentInvite: null,
        isInviteModalVisible: false,
        isInviteSaving: false
    }
  },
  provide() {
    return {
    }
  },
  mounted() {
        console.log('Need users...call load action from mounted.');
        this.load();
  },
  computed: {
    ...mapGetters({
        allUsers: 'usersModule/getUsers',
        getCurrentUserInRole: 'getCurrentUserInRole',
        getUserByEmailAddress: 'usersModule/getUserByEmailAddress',
        getDisplayRole: 'usersModule/getDisplayRole',
        providers: 'providersModule/getProviders',
        providerDetail: 'providersModule/getProviderById',
        account: 'getCurrentUser',
    })
  },
  methods: {
    initListForCurrentUser() {
        // set current extended user
        this.currentExtendedUser = this.getUserByEmailAddress(this.account.username);
        console.log(this.currentExtendedUser);
            
        if (this.getCurrentUserInRole('Task.Admin')) {
            this.sortedUsers = this.allUsers;
        } else if (this.getCurrentUserInRole('Task.Manager')) {
            console.log(this.currentExtendedUser.provider);
            this.sortedUsers = this.allUsers.filter(user => user != null && user.provider != null && user.provider == this.currentExtendedUser.provider);
        }
        this.getSortedUsers();
    },
    sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;

      this.sortedUsers = this.getSortedUsers();
    },
    getSortedUsers:function() {
      return this.sortedUsers.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    getNameForProviderId(providerId) {
        let _provider = this.providers.find(p => p.id == providerId);
        return _provider != null ? _provider.name : '';
    },
    async load() {
        console.log('List Users - load()');
        this.isLoading = true;
        await this.$store.dispatch("usersModule/fetchAllUsers").then(() => {
            console.log('List Users - END load()');
            this.initListForCurrentUser();   
            this.isLoading = false;
        });
        
    },
    formatToShortDate(date) {
        return formatToShortDate(date);
    },
  }
};
</script>
