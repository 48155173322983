import axios from 'axios'; // at the start of your <script> tag, before you "export default ..."
import { VUE_APP_VSE_USER_GROUP_ID,
    VUE_APP_VSE_MANAGER_GROUP_ID,
    VUE_APP_VSE_ADMIN_GROUP_ID } from '../../utils/constants.js';
import _ from 'lodash';

export default {
    namespaced: true,
    state() {
        return {
            axiosConfig: {
                headers: {
                    "Content-Type": "application/json",
                    "x-functions-key": "NtKvK1uS5Rs-ELAFTgktiKZA4hMUvzklWAoqPkymcLnaAzFugpHAVQ==",
                    "Referrer-Policy": "origin-when-cross-origin",
                },
            },
            axiosVseUserSettingsConfig: {
                headers: {
                    "Content-Type": "application/json",
                    "x-functions-key": "irjuAB68azla1rG2PeFDl1i39bfg5GaV3lVshfvv2nekBCzFAxHZOw==",
                    "Referrer-Policy": "origin-when-cross-origin",
                },
            },
            axiosAdAddUserConfig: {
                headers: {
                    "Content-Type": "application/json",
                    "x-functions-key": "4QlITBHDKdsl5ozAnayXKf1mVBqqvznYS7ReH5WiqMOZfmo4qtUAKQ==",
                    "Referrer-Policy": "origin-when-cross-origin",
                },
            },
            axiosAdAddMemberToGroupConfig: {
                headers: {
                    "Content-Type": "application/json",
                    "x-functions-key": "Su0GdsCOE2UIeE6ZvOQyhtHi1pDLWgmuyGpTZ4OpzIPv6/kYFO2gTg==",
                    "Referrer-Policy": "origin-when-cross-origin",
                },
            },
            axiosAdRemoveMemberFromGroupConfig: {
                headers: {
                    "Content-Type": "application/json",
                    "x-functions-key": "UpZFKULNkDPWYcXZTtfEuvagfghFm0y3TM1y5rmtsYfauGFXbI/5pQ==",
                    "Referrer-Policy": "origin-when-cross-origin",
                },
            },
            axiosVseAddUserSetting: {
                headers: {
                    "Content-Type": "application/json",
                    "x-functions-key": "aYW0eca8tUbpq1kXtRuxNTUn4zE0VIiW3YXaDF2vOOufsl0K4wNQMQ==",
                    "Referrer-Policy": "origin-when-cross-origin",
                },
            },
            axiosGetUserDetailsSetting: {
                headers: {
                    "Content-Type": "application/json",
                    "x-functions-key": "bsuoJ9ArVYubaqyk7loGQ2y9s9h3jDPJwWXZ0YV1nu4n0fisLdeZ8Q==",
                    "Referrer-Policy": "origin-when-cross-origin",
                },
            },
            axiosVseUpdateUserSetting: {
                headers: {
                    "Content-Type": "application/json",
                    "x-functions-key": "spHPBZclHlu2Y4YZzCUlXKSCwMTfaEZqWw0h9gw2bj5Alhba9NOyUw==",
                    "Referrer-Policy": "origin-when-cross-origin",
                },
            },
            axiosVseDeleteUserSetting: {
                headers: {
                    "Content-Type": "application/json",
                    "x-functions-key": "HZNygRFrICvyEQvq5b4PGdgxXgqldwal7e/qfTGd/Gg3V3lq8FrpBA==",
                    "Referrer-Policy": "origin-when-cross-origin",
                },
            },
            allUsers: [],
            admins: [],
            managers: [],
            users: [],
            allUsersSorted: [],
            currentUser: null,
            grabFromCache: false,
        };
    },
    getters: {
        getUsers(state) {
            console.log("Accessing getUsers getter...");
            if (state.allUsers != null && state.allUsers.length > 0) {
                console.log("State detected in getter. Length: " + state.allUsers.length);
            }
            return state.allUsers;
        },
        getDisplayRole: (state) => (roleId) => {
            console.log(state);
            var displayRole = null;
            switch (roleId) {
                case VUE_APP_VSE_USER_GROUP_ID:
                    displayRole = "Salesperson";
                    break;
                case VUE_APP_VSE_MANAGER_GROUP_ID:
                    displayRole = "Manager";
                    break;
                case VUE_APP_VSE_ADMIN_GROUP_ID:
                    displayRole = "Admin";
                    break;
                default:
                    return null;
            }
            return displayRole;
        },
        getDisplayRoles() {
            return [ 'Salesperson', 'Manager', 'Admin'];
        },
        getRoleId: (state) => (displayRole) => {
            console.log(state);
            var roleId = null;
            switch (roleId) {
                case "Salesperson":
                    roleId = VUE_APP_VSE_USER_GROUP_ID;
                    break;
                case "Manager":
                    roleId = VUE_APP_VSE_MANAGER_GROUP_ID;
                    break;
                case "Admin":
                    roleId = VUE_APP_VSE_ADMIN_GROUP_ID;
                    break;
                default:
                    return null;
            }
            return displayRole;
        },
        getRoleIds() {
            return [ VUE_APP_VSE_USER_GROUP_ID, VUE_APP_VSE_MANAGER_GROUP_ID, VUE_APP_VSE_ADMIN_GROUP_ID];
        },
        getUserByEmailAddress: (state) => (emailAddress) => {
            if (null != state.allUsers) {
                console.log(state.allUsers);
                return state.allUsers.find((user) => user.email != null && emailAddress != null && user.email.toLowerCase() == emailAddress.toLowerCase());
            } else {
                console.log(state.allUsers);
                return null;
            }
        },
        getUserById: (state) => (id) => {
            if (null != state.allUsers) {
                return state.allUsers.find((user) => user.id === id);
            } else {
                console.log(state.allUsers);
                return null;
            }
        },
        count: (state) => {
            if (state.allUsers == null) {
                return 0;
            } else {
                return state.allUsers.length;
            }
        },
        countByProvider: (state) => (providerId) => {
            console.log(providerId);
            if (null != state.allUsers) {
                console.log(state.allUsers);
                let _users = state.allUsers.filter((user) => user != null != user.provider && user.provider == providerId);
                return _users != null && _users.length > 0 ? _users.length : 0;
            } else {
                console.log(state.allUsers);
                return 0;
            }
        },
        grabFromCache: (state) => {
            return state.grabFromCache;
        },
    },
    mutations: {
        SET_USERS(state, users) {
            console.log("Set users mutation...");
            console.log(users);
            state.users = users;
        },
        SET_MANAGERS(state, managers) {
            console.log("Set managers mutation...");
            console.log(managers);
            state.managers = managers;
        },
        SET_ADMINS(state, admins) {
            console.log("Set admins mutation...");
            console.log(admins);
            state.admins = admins;
        },
        SET_ALL_USERS(state, allUsers) {
            console.log("Set allUsers mutation...");
            console.log(allUsers);
            state.allUsers = allUsers;
            state.grabFromCache = true;
        },
        SET_ADD_USER(state, newUser) {
            console.log("Set new user mutation...");
            console.log(newUser);
            state.allUsers.push(newUser);
            state.grabFromCache = false;
        },
        SET_UPDATE_USER(state, user) {
            console.log("Set update user mutation...");
            console.log(user);
            const index = state.allUsers.findIndex((x) => x.id === user.id);
            if (index != null && index > -1) {
                state.allUsers[index] = user;
            }
        },
        DELETE_USER(state, userId) {
            console.log("Set update user mutation...");
            const index = state.allUsers.findIndex(x => x.id === userId);
            if (index !== undefined) {
                state.allUsers.splice(index, 1);
            } 
        },
    },
    actions: {
        async fetchAllUsers({ commit, state }) {
            let promisesToCall = [];

            let userGroupData = {
                groupId: `${VUE_APP_VSE_USER_GROUP_ID}`,
            };
            let mgrGroupData = {
                groupId: `${VUE_APP_VSE_MANAGER_GROUP_ID}`,
            };
            let adminGroupData = {
                groupId: `${VUE_APP_VSE_ADMIN_GROUP_ID}`,
            };

            promisesToCall.push(axios.post("https://prolitecdataservices.azurewebsites.net/api/adListApplicationUsers", userGroupData, state.axiosConfig));
            promisesToCall.push(axios.post("https://prolitecdataservices.azurewebsites.net/api/adListApplicationUsers", mgrGroupData, state.axiosConfig));
            promisesToCall.push(axios.post("https://prolitecdataservices.azurewebsites.net/api/adListApplicationUsers", adminGroupData, state.axiosConfig));
            promisesToCall.push(axios.get("https://prolitecdataservices.azurewebsites.net/api/vseListUserSettings", state.axiosVseUserSettingsConfig));

            return await Promise.all(promisesToCall)
                .then((results) => {
                    console.log("Users retrieved...");
                    console.log(results);
                    var users = _.map(results[0].data.res.value, (o) => _.extend({ role: "Salesperson" }, o));
                    users = users.map((elem) => {
                        elem.email = elem.mail.toLowerCase();
                        delete elem.mail;
                        return elem;
                    });
                    var managers = _.map(results[1].data.res.value, (o) => _.extend({ role: "Manager" }, o));
                    managers = managers.map((elem) => {
                        elem.email = elem.mail.toLowerCase();
                        delete elem.mail;
                        return elem;
                    });
                    var admins = _.map(results[2].data.res.value, (o) => _.extend({ role: "Admin" }, o)); //.map(({mail}) => ({email : mail}));
                    admins = admins.map((elem) => {
                        elem.email = elem.mail.toLowerCase();
                        delete elem.mail;
                        return elem;
                    });
                    const mergedArray = _.unionBy(managers, admins, "email");
                    const newMergedArray = _.unionBy(mergedArray, users, "email");
                    let adMergedArray = newMergedArray.map((elem) => {
                        elem.activeDirectoryMemberId = elem.id;
                        delete elem.id;
                        return elem;
                    });
                    const userSettings = results[3].data.items;
                    console.log(users);
                    console.log(userSettings);
                    //const mergedUserList = _.unionBy(this.sortedUsers, userSettings, 'email');
                    var mergedList = _.map(adMergedArray, function (item) {
                        return _.extend(item, _.find(userSettings, { email: item.email }));
                    });

                    console.log(mergedList);
                    commit("SET_ALL_USERS", mergedList);
                    return mergedList;
                })
                .catch((error) => {
                    console.error("API Error.", error);
                });
        },
        async fetchUsers({ commit, dispatch, state }) {
            try {
                const userGroupId = VUE_APP_VSE_USER_GROUP_ID;
                console.log("UserGroupId: " + userGroupId);
                var postData = {
                    groupId: `${userGroupId}`,
                };
                return await axios
                    .post("https://prolitecdataservices.azurewebsites.net/api/adListApplicationUsers", postData, state.axiosConfig)
                    .then((response) => {
                        if (response.status == 200) {
                            //setFragranceItems(state, response.data)
                            console.log("get Users response...");
                            console.log(response.data);
                            return response.data;
                        }
                    })
                    .then((data) => {
                        var users = _.map(data.res.value, (o) => _.extend({ role: "Salesperson" }, o));
                        users = users.map((elem) => {
                            elem.email = elem.mail.toLowerCase();
                            delete elem.mail;
                            return elem;
                        });
                        commit("SET_USERS", users);
                        const mergedArray = _.unionBy(state.managers, state.admins, "email");
                        const newMergedArray = _.unionBy(mergedArray, state.users, "email");
                        commit("SET_ALL_USERS", newMergedArray);

                        // get user settings for each user
                        dispatch("fetchVseUserSettings", commit, state);
                    })
                    .catch((error) => {
                        console.error("API Error.", error);
                    });
                //new Error(response.message || 'Failed to fetch requests.'));
            } finally {
                //commit('contentLoading', false);
            }
        },
        async fetchManagers({ commit, dispatch, state }) {
            // Set Group ID = Prolitec VSE Web Application Manager Group
            const managerGroupId = VUE_APP_VSE_MANAGER_GROUP_ID;
            var postData = {
                groupId: `${managerGroupId}`,
            };

            axios
                .post("https://prolitecdataservices.azurewebsites.net/api/adListApplicationUsers", postData, state.axiosConfig)
                .then((response) => {
                    if (response.status == 200) {
                        return response.data;
                    }
                })
                .then((data) => {
                    var managers = _.map(data.res.value, (o) => _.extend({ role: "Manager" }, o));
                    managers = managers.map((elem) => {
                        elem.email = elem.mail.toLowerCase();
                        delete elem.mail;
                        return elem;
                    });
                    commit("SET_MANAGERS", managers);
                    dispatch("fetchUsers", commit, dispatch);
                })
                .catch((error) => {
                    console.error("API Error.", error);
                });
        },
        async fetchAdmins({ commit, dispatch, state }) {
            // Set Group ID = Prolitec VSE Web Application Admin Group
            const adminGroupId = VUE_APP_VSE_ADMIN_GROUP_ID;
            var postData = {
                groupId: `${adminGroupId}`,
            };
            axios
                .post("https://prolitecdataservices.azurewebsites.net/api/adListApplicationUsers", postData, state.axiosConfig)
                .then((response) => {
                    if (response.status == 200) {
                        return response.data;
                    }
                })
                .then((data) => {
                    //this.payload = data.res.value;
                    var admins = _.map(data.res.value, (o) => _.extend({ role: "Admin" }, o)); //.map(({mail}) => ({email : mail}));
                    admins = admins.map((elem) => {
                        elem.email = elem.mail.toLowerCase();
                        delete elem.mail;
                        return elem;
                    });
                    commit("SET_ADMINS", admins);
                    console.log("this.admins:");
                    console.log(admins);
                    dispatch("fetchManagers", commit, dispatch);
                    //this.fetchManagers(commit, state);
                })
                .catch((error) => {
                    console.error("API Error.", error);
                });
        },
        async fetchVseUserSettings({ commit, state }) {
            axios
                .get("https://prolitecdataservices.azurewebsites.net/api/vseListUserSettings", state.axiosVseUserSettingsConfig)
                .then((response) => {
                    if (response.status == 200) {
                        console.log("get vse settings response...");
                        return response.data;
                    }
                })
                .then((data) => {
                    const userSettings = data.items;
                    console.log(userSettings);
                    //const mergedUserList = _.unionBy(this.sortedUsers, userSettings, 'email');
                    const mergedList = _.map(state.allUsers, function (item) {
                        return _.extend(item, _.find(userSettings, { email: item.email }));
                    });
                    console.log(mergedList);
                    commit("SET_ALL_USERS", mergedList);
                    //state.allUsers = mergedList;
                })
                .catch((error) => {
                    console.error("API Error.", error);
                });
        },
        async findExistingAdUser(context, newUser) {
            const memberEmail = newUser.email.toLowerCase();
            console.log({ newUser });
            var postInviteData = {
                email: `${memberEmail}`,
            };

            return axios
                .post("https://prolitecdataservices.azurewebsites.net/api/adGetUserByEmailAddress", postInviteData, context.state.axiosGetUserDetailsSetting)
                .then((response) => {
                    if (response.status == 200) {
                        console.log(response);
                        return response.data;
                    }
                })
                .then((data) => {
                    console.log(data);

                    if (data.user.value.length > 0 && data.user.value[0].id && data.user.value[0].id.length > 12) {
                        console.log("User exists!");
                        var user = {
                            id: data.user.value[0].id,
                            firstName: data.user.value[0].givenName,
                            lastName: data.user.value[0].surname,
                            email: data.user.value[0].mail.toLowerCase(),
                            role: newUser.role,
                            provider: newUser.provider,
                            joined: newUser.joined,
                            exists: true,
                        };
                        return user;
                        //this.saveAddRole();
                    } else {
                        return null;
                    }
                })
                .catch((error) => {
                    console.error("API Error.", error);
                });
        },
        async inviteUser(context, newUser) {
            // 1. Save Base User to Azure AD
            const memberEmail = newUser.email.toLowerCase();
            var postInviteData = {
                memberEmail: `${memberEmail}`,
            };
            return axios
                .post("https://prolitecdataservices.azurewebsites.net/api/adAddUser", postInviteData, context.state.axiosAdAddUserConfig)
                .then((response) => {
                    if (response.status == 200) {
                        let newMember = response.data.newUser.invitedUser;
                        return newMember;
                    }
                })
                .catch((error) => {
                    console.error("API Error.", error);
                });
        },
        async saveAddUser(context, newUser) {
            // 1. Save Base User to Azure AD
            // 2. Add User to Group based on Role
            // 3. Add additional props to VSE Cosmos DB User-Settings
            const memberEmail = newUser.email.toLowerCase();
            var postInviteData = {
                memberEmail: `${memberEmail}`,
            };
            axios
                .post("https://prolitecdataservices.azurewebsites.net/api/adAddUser", postInviteData, context.state.axiosAdAddUserConfig)
                .then((response) => {
                    if (response.status == 200) {
                        return response.data;
                    }
                })
                .then((data) => {
                    console.log(data);
                    newUser.id = data.newUser.invitedUser.id;
                    console.log("this.savedUser.id: " + newUser.id);
                    newUser.email = data.newUser.invitedUserEmailAddress.toLowerCase();
                    newUser.joined = new Date();
                    context.dispatch("saveAddRole", newUser, context);
                    //this.saveAddRole();
                })
                .catch((error) => {
                    console.error("API Error.", error);
                });
        },
        async saveAddRole(context, newUser) {
            console.log(newUser);
            const savedUserId = newUser.id;
            const selectedGroupId = newUser.role;

            var postGroupData = {
                memberId: `${savedUserId}`,
                groupId: `${selectedGroupId}`,
            };
            console.log(postGroupData);
            axios
                .post("https://prolitecdataservices.azurewebsites.net/api/adAddGroupMember", postGroupData, context.state.axiosAdAddMemberToGroupConfig)
                .then((response) => {
                    if (response.status == 200) {
                        console.log(response);
                        context.dispatch("saveAddUserSettings", newUser, context);
                    } else {
                        console.log(response);
                    }

                    // redirect to user list
                    //this.$router.push('/users');
                })
                .catch((error) => {
                    console.error("API Error.", error);
                });
        },
        async addUserSettings(context, newUser) {
            var postVseUserSettingData = {
                email: newUser.email.toLowerCase(),
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                provider: newUser.provider,
                joined: newUser.joined,
                role: newUser.role,
                activeDirectoryMemberId: newUser.activeDirectoryMemberId,
            };
            return axios
                .post("https://prolitecdataservices.azurewebsites.net/api/vseCreateUserSetting", postVseUserSettingData, context.state.axiosVseAddUserSetting)
                .then((response) => {
                    if (response.status == 200) {
                        console.log(response);
                        newUser.id = response.data.id;
                        context.commit("SET_ADD_USER", newUser);
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.error("API Error.", error);
                });
        },
        async updateUserSettings(context, user) {
            console.log("joined: " + user.joined);
            var postVseUserSettingData = {
                id: user.id,
                email: user.email.toLowerCase(),
                firstName: user.firstName,
                lastName: user.lastName,
                provider: user.provider,
                joined: user.joined,
                role: user.role,
                activeDirectoryMemberId: user.activeDirectoryMemberId,
            };
            return axios
                .post("https://prolitecdataservices.azurewebsites.net/api/vseUpdateUserSetting", postVseUserSettingData, context.state.axiosVseUpdateUserSetting)
                .then((response) => {
                    if (response.status == 200) {
                        context.commit("SET_UPDATE_USER", user);
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.error("API Error.", error);
                });
        },
        async updateUserRoles(context, user) {
            console.log(user);
            // update user roles (remove all, then add selected)
            // then update user settings
            let promisesToCall = [];

            let userGroupData = {
                memberId: `${user.activeDirectoryMemberId}`,
                groupId: `${VUE_APP_VSE_USER_GROUP_ID}`,
            };
            let mgrGroupData = {
                memberId: `${user.activeDirectoryMemberId}`,
                groupId: `${VUE_APP_VSE_MANAGER_GROUP_ID}`,
            };
            let adminGroupData = {
                memberId: `${user.activeDirectoryMemberId}`,
                groupId: `${VUE_APP_VSE_ADMIN_GROUP_ID}`,
            };
            let updatedUserRole = {
                memberId: `${user.activeDirectoryMemberId}`,
                groupId: `${user.role}`,
            };
            let axiosDeleteHeaders = context.state.axiosAdRemoveMemberFromGroupConfig;

            promisesToCall.push(axios.post("https://prolitecdataservices.azurewebsites.net/api/adRemoveMemberFromGroup", userGroupData, axiosDeleteHeaders));
            promisesToCall.push(axios.post("https://prolitecdataservices.azurewebsites.net/api/adRemoveMemberFromGroup", mgrGroupData, axiosDeleteHeaders));
            promisesToCall.push(axios.post("https://prolitecdataservices.azurewebsites.net/api/adRemoveMemberFromGroup", adminGroupData, axiosDeleteHeaders));
            // if user role set to 0, we are deleting only
            if (user.role != "0") {
                promisesToCall.push(axios.post("https://prolitecdataservices.azurewebsites.net/api/adAddGroupMember", updatedUserRole, context.state.axiosAdAddMemberToGroupConfig));
            }

            Promise.all(promisesToCall)
                .then((results) => {
                    console.log("Roles updated...");
                    console.log(results);
                    context.commit("SET_UPDATE_USER", user);
                })
                .catch((error) => {
                    console.error("API Error.", error);
                });
        },
        async deleteUserSetting({ commit }, user) {
            try {
                //commit('contentLoading', true);
                await axios.delete('https://prolitecdataservices.azurewebsites.net/api/vseDeleteUserSetting', { headers: {
                    'Content-Type': 'application/json',
                    'x-functions-key':'HZNygRFrICvyEQvq5b4PGdgxXgqldwal7e/qfTGd/Gg3V3lq8FrpBA==',
                    'Referrer-Policy': 'origin-when-cross-origin'
                }, data: { id: user.id, email: user.email } })
                .then((response) => {
                  if (response.status == 200) {
                    console.log(response.data);
                    commit('DELETE_USER', user.id);
                  }
                })
            .catch(error => {
                console.error('API Error.',error);
                });
                  //new Error(response.message || 'Failed to fetch requests.'));
            } finally {
                //commit('contentLoading', false);
            }
        }
    },
};