<template>
  <section v-if="getCurrentUserInRole('Task.Admin') | getCurrentUserInRole('Task.Manager')" id="QuestionList" class="container">
    <breadcrumbVue :items="crumbs"></breadcrumbVue>
    <div class="d-flex align-items-between">
        <div class="col">
            <h1>Quiz Questions</h1>
            <p>Click the question below to edit. 
                Add or sort questions by using the buttons at the bottom of the question list.</p>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-4">
            <jumpNavigation></jumpNavigation>
        </div>
    </div>
    <div v-if="isLoading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- Question List -->
    <div v-else class="table-responsive">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Question</th>
                    <th>Type</th>
                    <th>Visibility</th>
                    <th>Answers</th>
                </tr>
            </thead>
            <draggable v-model="sortedQuestions" tag="tbody" handle=".handle" :move="checkMove" class="dragArea w-full"
            @start="dragging = true"
            @end="dragging = false">
                <tr v-for="question in sortedQuestions" :key="question.id">
                    <td><BIconGripHorizontal v-if="isSortOrderEditable" class="handle" /><span v-else>{{ question.sortOrder }}</span></td>
                    <td><router-link v-if="question != null && question.id != null" :to="{ name: 'QuestionDetail', params: { questionId: question.id }}" class="link link-primary">{{ question.questionText }}</router-link></td>
                    <td>{{ question.type }}</td>
                    <td>{{ question.publishState }}</td>
                    <td>{{ question.answers.length }}</td>
                </tr>
            </draggable>
        </table>
    </div>
    <div>
        <button v-if="!isSortOrderEditable" class="btn btn-primary me-2" @click="allowSortOrderEdit">Edit Sort Order</button>
        <button v-if="isSortOrderEditable" class="btn btn-secondary me-2" @click="cancelSortOrderEdit">Cancel</button>
        <button v-if="isSortOrderEditable" class="btn btn-primary me-2" @click="saveSortOrderEdit">
            <span>Save</span>
            <span v-show="isSortOrderUpdating" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
        </button>
        <button v-if="!isSortOrderEditable" class="btn btn-primary" @click="showAddQuestionModal">Add Question</button>
    </div>
    <!-- ADD QUESTION MODAL -->
    <div v-if="isAddQuestionModalVisible" class="modal fade show" id="myModal" style="display: block;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add Question</h4>
                    <button type="button" class="btn-close" @click="closeAddQuestionModal" data-dismiss="modal" aria-label="Close">
                        <span class="visually-hidden" aria-hidden="true" >×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col text-center">
                            <img src="../../assets/q-type-photo-text.png" width="120" alt="Photo question type icon" class="mb-2" />
                            <button type="button" class="btn btn-primary" @click="addPhotoQuestion" data-dismiss="modal">Photo + Text</button>
                        </div>
                        <div class="col text-center">
                            <img src="../../assets/q-type-text-only.png" width="120" alt="Single choice question type icon" class="mb-2" />
                            <button type="button" class="btn btn-primary" @click="addSingleChoiceQuestion" data-dismiss="modal">Single Choice</button>
                        </div>
                        <div class="col text-center">
                            <img src="../../assets/q-type-text-only.png" width="120" alt="Multiple choice question type icon" class="mb-2" />
                            <button type="button" class="btn btn-primary" @click="addMultiChoiceQuestion" data-dismiss="modal">Multiple Choice</button>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeAddQuestionModal" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="isAddQuestionModalVisible" class="modal-backdrop fade show"></div>
  </section>
</template>

<script>
//import axios from 'axios'; // at the start of your <script> tag, before you "export default ..."
import breadcrumbVue from '../../components/breadcrumb.vue';
import jumpNavigation from '../../components/jump-navigation.vue';
import { VueDraggableNext } from "vue-draggable-next";
import {mapGetters } from 'vuex';

export default {
  name: 'QuestionList',
  components: {
    breadcrumbVue,
    jumpNavigation,
    draggable: VueDraggableNext,
  },
  data() {
    return {
      crumbs: [{
        name: 'Dashboard',
        url: '/dashboard'
            }, {
                name: 'Quiz Questions',
                url: '/questions'
      }],
      sortedQuestions: [],
      currentSort: '',
      currentSortDir: 'asc',
      isLoading: false,
      isAddQuestionModalVisible: false,
      isSortOrderEditable: false,
      isSortOrderUpdating: false,
      display: "Handle",
      order: 1,
      instruction: "Drag using the handle icon",
      dragging: false
    }
  },
  mounted() {
    // get from API
        console.log('Need questions...call load action from mounted.');
        this.load();
  },
  computed: {
    ...mapGetters({
        questions: 'questionsModule/getQuestions',
        getCurrentUserInRole: 'getCurrentUserInRole',
    }),
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    }
  },
  methods: {
    //...mapActions('')
    async load() {
        console.log('List Questions - load()');
        this.isLoading = true;
        await this.$store.dispatch("questionsModule/fetchQuestions").then(() => {
            console.log('List Questions - END load()');
            this.sortedQuestions = this.questions;
            this.sort('sortOrder');
            this.isLoading = false;
        });
    },
    sort:function(s) {
      //if s == current sort, reverse
    //   if(s === this.currentSort) {
    //     this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    //   }
      this.currentSort = s;

      this.sortedQuestions = this.getSortedQuestions();
    },
    getSortedQuestions:function() {
      return this.sortedQuestions.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    showAddQuestionModal: function() {
        this.isAddQuestionModalVisible = true;
    },
    closeAddQuestionModal: function() {
        this.isAddQuestionModalVisible = false;
    },
    addPhotoQuestion: function() {
        // set current question to new question of type photo + navigate to add page
        const newQ = {
            type: 'Photo + Text',
            publishState: 'Draft',
            sortOrder: this.questions.length + 1,
            answers: []
        };
        this.setCurrentQuestion(newQ);
    },
    addSingleChoiceQuestion: function() {
        const newQ = {
            type: 'Single Choice',
            publishState: 'Draft',
            sortOrder: this.questions.length + 1,
            answers: []
        };
        this.setCurrentQuestion(newQ);
    },
    addMultiChoiceQuestion: function() {
        const newQ = {
            type: 'Multiple Choice',
            publishState: 'Draft',
            sortOrder: this.questions.length + 1,
            answers: []
        };
        this.setCurrentQuestion(newQ);
    },
    setCurrentQuestion(newQuestion) {
        this.$store.commit("questionsModule/SET_CURRENT_QUESTION", newQuestion);
        this.$router.push('/questions/add');
    },
    allowSortOrderEdit() {
        this.isSortOrderEditable = true;
    },
    cancelSortOrderEdit() {
        this.isSortOrderEditable = false;
        this.sort('sortOrder');
    },
    async saveSortOrderEdit() {
        // update each question's sort order, then save
                // set sort order...loop through each item and set sort order = index + 1
        this.isSortOrderUpdating = true;
        let questionsCount = this.sortedQuestions.length;

        for (var [index, question] of this.sortedQuestions.entries()) {
            console.log(index);
            question.sortOrder = index + 1;
            await this.$store.dispatch('questionsModule/updateQuestion', question).then(() => {
                if (index == questionsCount - 1) {
                    this.isSortOrderUpdating = false;
                } else {
                    console.log('Continue updating...');
                }
            });
        }

        this.isSortOrderEditable = false;
    },
    checkMove: function(e) {
        console.log(e);
        let id = e.draggedContext.element.id;
        console.log(id);
        console.log("Future index: " + e.draggedContext.futureIndex);
    }
  }
};
</script>

<style scoped>
@media (min-width: 576px) {
    .modal-dialog {
        max-width: 560px;
    }
}
</style>