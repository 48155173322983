<template>
  <router-link to="/" class="navbar-brand">
    <img v-if="this.currentTheme == 'light'" src="../assets/Prolitec_Grey_tag_Ambient.png" :key="this.currentTheme" alt="Prolitec logo" width="181" />
    <img v-if="this.currentTheme == 'dark'" src="../assets/prolitec-reverse-white.png" :key="this.currentTheme" alt="Prolitec logo" width="181" />
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'HeaderBarBrand',
  computed: {
    ...mapGetters({
        currentTheme: 'uiModule/getCurrentTheme',
    })
  },
};
</script>
