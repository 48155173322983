<template>
  <section id="StyleGuide" class="container mb-5">
    <h1 class="my-4">Style Guide</h1>
    <h2 class="my-4">Headings and Text</h2>
    <h1>This is an H1.</h1>
    <h2>This is an H2.</h2>
    <h3>This is an H3.</h3>
    <h4>This is an H4.</h4>
    <h5>This is an H5.</h5>
    <h6>This is an H6.</h6>
    <p class="">This is standard paragraph copy.</p>
    <p class="">This is secondary paragraphy copy.</p>
    <h2 class="my-4">Links</h2>
    <a href="#" class="link link-primary">Primary link</a>
    <a href="#" class="link link-secondary">Secondary link</a>
    <a href="#" class="link link-success">Success link</a>
    <a href="#" class="link link-danger">Danger link</a>
    <a href="#" class="link link-warning">Warning link</a>
    <a href="#" class="link link-info">Info link</a>
    <a href="#" class="link link-light">Light link</a>
    <a href="#" class="link link-dark">Dark link</a>
    <div class="my-4">
      <h2>Navigation</h2>
      <h3>Breadcrumbs</h3>
      <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">Library</a></li>
          <li class="breadcrumb-item active" aria-current="page">Data</li>
        </ol>
      </nav>
    </div>
    <div>
      <h2 class="my-4">Buttons</h2>
      <button type="button" class="btn btn-primary">Primary</button>
      <button type="button" class="btn btn-secondary">Secondary</button>
      <button type="button" class="btn btn-success">Success</button>
      <button type="button" class="btn btn-danger">Danger</button>
      <button type="button" class="btn btn-warning">Warning</button>
      <button type="button" class="btn btn-info">Info</button>
      <button type="button" class="btn btn-light">Light</button>
      <button type="button" class="btn btn-dark">Dark</button>

      <button type="button" class="btn btn-link">Link</button>
      <h2 class="my-4">Forms</h2>
      <h3 class="my-4">Text Input</h3>
      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">Email address</label>
        <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com">
      </div>
      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">Disabled</label>
        <input class="form-control" type="text" placeholder="Disabled input, could be enabled in some conditions" aria-label="Disabled input example" disabled>
      </div>
      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">Read Only Input</label>
        <input class="form-control" type="text" value="Disabled readonly input" aria-label="Disabled input example" disabled readonly>
      </div>
      <div class="mb-3">
        <label for="exampleFormControlTextarea1" class="form-label">Example textarea</label>
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
      </div>

      <h3 class="my-4">Select</h3>
      <select class="form-select" aria-label="Default select example">
        <option selected>Open this select menu</option>
        <option value="1">One</option>
        <option value="2">Two</option>
        <option value="3">Three</option>
      </select>

      <h3 class="my-4">Checkboxes</h3>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
        <label class="form-check-label" for="flexCheckDefault">
          Default checkbox
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
        <label class="form-check-label" for="flexCheckChecked">
          Checked checkbox
        </label>
      </div>

      <h3 class="my-4">Radio Buttons (switches)</h3>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
        <label class="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label>
      </div>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked>
        <label class="form-check-label" for="flexSwitchCheckChecked">Checked switch checkbox input</label>
      </div>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDisabled" disabled>
        <label class="form-check-label" for="flexSwitchCheckDisabled">Disabled switch checkbox input</label>
      </div>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckCheckedDisabled" checked disabled>
        <label class="form-check-label" for="flexSwitchCheckCheckedDisabled">Disabled checked switch checkbox input</label>
      </div>
      <h3>Color Picker</h3>
      <input type="color" class="form-control form-control-color" id="exampleColorInput" value="#563d7c" title="Choose your color">
    </div>
    <div>
      <h2 class="my-4">Tables</h2>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">First</th>
            <th scope="col">Last</th>
            <th scope="col">Handle</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1</th>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td>Jacob</td>
            <td>Thornton</td>
            <td>@sk8rluv</td>
          </tr>
          <tr>
            <th scope="row">3</th>
            <td colspan="2">Larry the Bird</td>
            <td>@twitter</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <h2 class="my-4">Modals</h2>
      <!-- Button trigger modal -->
      <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
        Launch demo modal
      </button>

      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header py-2">
              <h3 class="modal-title" id="exampleModalLabel">Modal title</h3>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <h5>About</h5>
              <p>A classic fresh, aromatic scent creating a casual masculine environment. 
                A fresh feel is created through dewy watery notes combined with bright citrus notes. 
                The addition of clean green aldehydic notes which enhance the lavender herbal accord, 
                together provide an aromatic blend with a rich diffusive masculine signature.</p>
              <h6>Adjectives</h6>
              <div class="tag-list">
                <a class="btn btn-tag mb-2" href="#" role="button">Casual</a>
                <a class="btn btn-tag mb-2" href="#" role="button">Classic</a>
                <a class="btn btn-tag mb-2" href="#" role="button">Masculine</a>
                <a class="btn btn-tag mb-2" href="#" role="button">Masking</a>
                <a class="btn btn-tag mb-2" href="#" role="button">Peaceful</a>
                <a class="btn btn-tag mb-2" href="#" role="button">Relaxing</a>
              </div>
              <h5>Mood Board</h5> 
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary">Download PDF</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'StyleGuide'
}
</script>

<style scoped>
#StyleGuide a, #StyleGuide button { margin-right: 0.5em; }

</style>
