<template>
  <section id="FragranceAdd" class="container">
    <div class="row mt-4">
        <breadcrumbVue :items="crumbs"></breadcrumbVue>
    </div>
    <div>
        <nav class="navbar navbar-edit navbar-expand fixed-top justify-content-between">
            <div class="container">
                <span>Add Fragrance: <span class="notranslate">{{ currentExtendedFragrance.name }}</span></span>
                <span>
                    <button @click="cancelAddFragrance" class="btn btn-secondary me-3">Cancel</button>
                    <button @click="validateAndSaveFragrance" class="btn btn-primary">
                        <span>Save</span>
                        <span v-show="isSaving" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    </button>
                </span> 
            </div>
        </nav>
    </div> 
    
    <div class="row mt-4">
        <div class="col">
            <label for="selectPublishState" class="form-label">Status</label>
            <select id="selectPublishState" v-model="currentExtendedFragrance.publishState" class="form-select" aria-label="Visibility">
            <option value="">Select an option</option>
            <option v-for="option in publishedStateOptions" v-bind:key="option" :value="option">{{ option }}</option>
            </select>
        </div>
    </div>
    <div class="row my-3">
        <div class="col">
            <div id="FileUploadHeroImage" class="input-group flex-column align-items-start mb-3">
                <label class="my-3" for="inputFileHeroImage">Hero Image</label>
                <img v-show="currentExtendedFragrance.heroImageUrl != null" :src="currentExtendedFragrance.heroImageUrl" class="img-hero" width="160" />
                <input type="file" class="upload upload-image visually-hidden" @change="uploadHeroImage"  accept=".jpg,.gif,.png" id="inputFileHeroImage">
                <label class="upload-status text-primary" id="labelHeroImageUploadStatus"></label>
                <label class="input-group-text btn btn-primary mt-3" for="inputFileHeroImage">
                    <span>Upload</span>
                    <span v-show="isHeroImageLoading" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                </label>
                <div> 
                    <span v-show="currentExtendedFragrance.heroImageUrl == null" class="text-danger">Please upload an image</span>
                </div>
                <!--<p>{{ downloadHeroImageUrl }}</p>-->
            </div>
        </div>
        <div class="col">
            <div id="FileUploadOneSheetPdf" class="input-group flex-column align-items-start mb-3">
                <label class="my-3" for="inputFileOneSheetPdf">One Sheet PDF</label>
                <input type="file" class="upload upload-pdf visually-hidden" @change="uploadOneSheetPdf"  accept=".pdf" id="inputFileOneSheetPdf">
                <a v-show="currentExtendedFragrance.oneSheetPdfUrl != null" :href="currentExtendedFragrance.oneSheetPdfUrl" class="link link-primary fs-5">{{ currentExtendedFragrance.oneSheetPdfFilename }}</a>
                <!--<label class="upload-status text-primary" id="labelOneSheetPdfUploadStatus"></label>-->
                <label class="input-group-text btn btn-primary mt-3" for="inputFileOneSheetPdf">
                    <span>Upload</span>
                    <span v-show="isOneSheetPdfLoading" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                </label>
                <div>
                    <span v-show="currentExtendedFragrance.oneSheetPdfUrl == null" class="text-danger">Please upload a pdf file</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row my-3">
        <div class="col">
            <label for="inputName" class="form-label">Name</label>
            <input id="inputName" v-model="currentExtendedFragrance.name" type="text" class="form-control notranslate" placeholder="Name" aria-label="Name">
        </div>
        <div class="col">
            <label for="inputSku" class="form-label">SKU</label>
            <input id="inputSku" v-model="currentExtendedFragrance.sku" type="text" class="form-control" placeholder="Sku" aria-label="Sku">
        </div>
        <div class="col">
            <label for="inputDetectionLevel" class="form-label">Detection Level</label>
            <input id="inputDetectionLevel" v-model="currentExtendedFragrance.detectionLevel" type="email" class="form-control" placeholder="Detection Level" aria-label="Detection Level">
        </div>
    </div>
    <div class="row my-3">
        <div class="col">
            <label for="inputShortDescription" class="form-label">Short Description</label>
            <input id="inputShortDescription" v-model="currentExtendedFragrance.shortDescription" type="text" class="form-control" placeholder="Short Description" aria-label="Short Description">
        </div>
    </div>
    <div class="row my-3">
        <div class="col">
            <label for="inputLongDescription" class="form-label">Long Description</label>
            <textarea id="inputLongDescription" v-model="currentExtendedFragrance.longDescription" type="text" rows="4" class="form-control" placeholder="Long Description" aria-label="Long Description"></textarea>
        </div>
    </div>
    <div class="row align-items-center my-3">    
        <div class="col">
            <label for="selectFamily" class="form-label">Family</label>
            <select id="selectFamily" v-model="currentExtendedFragrance.family" class="form-select" aria-label="Select family">
            <option value="0" selected>Select a family...</option>
            <option v-for="option in families" v-bind:key="option" :value="option">{{ option }}</option>
            </select>
        </div>
        <div class="col">
            <label for="selectCollection" class="form-label">Collection</label>
            <select id="selectCollection" v-model="currentExtendedFragrance.collection" class="form-select" aria-label="Select collection">
            <option value="0" selected>Select a collection...</option>
            <option v-for="option in collections" v-bind:key="option" :value="option">{{ option }}</option>
            </select>
        </div>
        <div class="col form-check pt-4">
            <input type="checkbox" che id="checkOdorNeutralizer" v-model="currentExtendedFragrance.isOdorNeutralizer" class="form-check-input ms-0" aria-label="Odor Neutralizer?">
            <label for="checkOdorNeutralizer" class="form-check-label ms-2">Odor Neutralizer</label>
        </div>
    </div>
    <!-- COLORS -->
    <div class="row my-5">
        <h2>Colors</h2>
        <fragranceColorSelect></fragranceColorSelect>
    </div>
    
    <!-- ADJECTIVES -->
    <adjectiveSelectedList></adjectiveSelectedList>

    <errorMessageModal v-if="showInvalidForm" @closeErrorMessageModal="onCloseErrorMessageModal" :errorTitle="errorTitle" :errorMessages="errorMessages"></errorMessageModal>
  </section>
</template>

<script>
import breadcrumbVue from '../../components/breadcrumb.vue';
import fragranceColorSelect from '../../components/fragrance-color-select.vue';
import adjectiveSelectedList from '../../components/fragrance-adjective-select.vue';
import errorMessageModal from '../../components/error-message-modal.vue';
import { uploadFile } from '../../utils/files.js';
import {mapGetters } from 'vuex';

export default {
    name: 'FragranceAdd',
    components: {
        breadcrumbVue,
        fragranceColorSelect,
        adjectiveSelectedList,
        errorMessageModal,
    },
    data() {
    return {
        crumbs: [{
        name: 'Dashboard',
        url: '/dashboard'
            }, {
                name: 'Fragrances',
                url: '/fragrances'
            },
            {
                name: 'Add Fragrance',
                url: '/fragrances/add'
            },
        ],
        pageState: 'view',
        errorMessages: [],
        errorTitle: 'Error Adding Fragrance',
        currentExtendedFragrance: {},
        calculatedVisibilityPercentage: null,
        isHeroImageAdded: false,
        isOneSheetPdfAdded: false,
        isLoading: false,
        isSaving: false,
        isHeroImageLoading: false,
        isOneSheetPdfLoading: false,
        isSavedFragranceExtendedLoading: false,
        showSaveSuccess: false,
        showSaveError: false,
        showInvalidForm: false,
        isDeleteModalVisible: false,
        currentName: null,
        currentId: null
    }
  },
  created() {
    this.populateExtendedFragrance();
  },
  mounted() {
    
  },
  computed: {
    ...mapGetters({
        publishedStateOptions: 'fragrancesExtendedModule/getPublishedStateOptions',
        collections: 'fragrancesExtendedModule/getCollections',
        families: 'fragrancesExtendedModule/getFamilies',
    }),  
    uploadUrl () {
        return this.$store.getters['filesModule/getUploadUrl']; 
    },
    downloadUrl () {
        return this.$store.getters['filesModule/getDownloadUrl']; 
    },
    uploadedHeroImageUrl () {
        return this.$store.getters['fragrancesExtendedModule/getUploadedHeroImageUrl']; 
    },
    uploadedOneSheetPdfUrl () {
        return this.$store.getters['fragrancesExtendedModule/getUploadedOneSheetPdfUrl']; 
    }
  },
  methods: {
    populateExtendedFragrance() {
        this.currentExtendedFragrance = {
            name: '',
            sku: '',
            shortDescription: '',
            longDescription: '',
            family: '',
            isOdorNeutralizer: false,
            collection: '',
            heroImageUrl: '',
            oneSheetPdfUrl: '',
            oneSheetPdfFilename: '',
            adjectives: [],
            colors: [],
            detectionLevel: null,
            publishState: 'Draft',
            isEnforceAdjectivesAndColors: false,
            isEnforceUploads: false
        }
        this.$store.commit('fragrancesExtendedModule/SET_CURRENT_FRAGANCE_EXTENDED', this.currentExtendedFragrance);
        return;
    },
    async uploadHeroImage(e) {
        this.isHeroImageLoading = true;
        console.log('uploadHeroImage...ViewFragrance');
        var file = e.target.files[0];
        var filename = e.target.files[0].name;
        console.log('filename: ' + filename);
        this.isHeroImageAdded = true;
        let payload = new FormData();
            payload.append('file', file);
        var payloadObject = {
            filename: filename,
            file: payload
        }
        console.log(payload.filename);
        console.log(payload);
        //this.$store.dispatch('fragrancesExtendedModule/uploadHeroImage', payloadObject);
        // await this.$store.dispatch('filesModule/uploadFile', payloadObject).then(() => {
        //     this.currentExtendedFragrance.heroImageUrl = this.downloadUrl;
        // }); 
        await uploadFile(payloadObject).then(downloadUrl => {
            this.currentExtendedFragrance.heroImageUrl = downloadUrl;
            console.log('Download URL: ' + downloadUrl);
            this.isHeroImageLoading = false;
        });    
    },
    async uploadOneSheetPdf(e) {
        this.isOneSheetPdfLoading = true;
        var file = e.target.files[0];
        var filename = e.target.files[0].name;
        this.isOneSheetPdfAdded = true;
        let payload = new FormData();
            payload.append('file', file);
        var payloadObject = {
            filename: filename,
            file: payload
        }
        
        await uploadFile(payloadObject).then(downloadUrl => {
            this.currentExtendedFragrance.oneSheetPdfUrl = downloadUrl;
            this.currentExtendedFragrance.oneSheetPdfFilename = filename;
            this.isOneSheetPdfLoading = false;
            console.log('Download URL: ' + downloadUrl);
        }); 
        
    },
    getDownloadUrlFromFilename(filename) {
        console.log(filename);
    },
    cancelAddFragrance() {
        this.$router.push('/fragrances');
    },
    async validateAndSaveFragrance() {
        this.errorMessages = [];
        this.showInvalidForm = false;
        var isValid = true;
        // check for name
        if (this.currentExtendedFragrance.name == null || this.currentExtendedFragrance.name.length == 0) {
            isValid = false;
            this.errorMessages.push('Fragrance Name is required.');
        }
        // check for sku
        if (this.currentExtendedFragrance.sku == null || this.currentExtendedFragrance.sku.length == 0) {
            isValid = false;
            this.errorMessages.push('Fragrance Sku is required.');
        }
        // check for text in short, long description
        if (this.currentExtendedFragrance.shortDescription == null || this.currentExtendedFragrance.shortDescription.length == 0) {
            isValid = false;
            this.errorMessages.push('Fragrance Short Description is required.');
        }
        if (this.currentExtendedFragrance.longDescription == null || this.currentExtendedFragrance.longDescription.length == 0) {
            isValid = false;
            this.errorMessages.push('Fragrance Long Description is required.');
        }
        // check for selected family, collection
        if (this.currentExtendedFragrance.family == null || this.currentExtendedFragrance.family.length == 0) {
            isValid = false;
            this.errorMessages.push('Fragrance Family is required.');
        }
        if (this.currentExtendedFragrance.collection == null || this.currentExtendedFragrance.collection.length == 0) {
            isValid = false;
            this.errorMessages.push('Fragrance Collection is required.');
        }
        if (this.isEnforceUploads) {
            // check for hero image
            if (this.currentExtendedFragrance.heroImageUrl == null || this.currentExtendedFragrance.heroImageUrl.length == 0) {
                isValid = false;
                this.errorMessages.push('Fragrance Hero Image is required.');
            }
            // check for PDF
            if (this.currentExtendedFragrance.oneSheetPdfUrl == null || this.currentExtendedFragrance.oneSheetPdfUrl.length == 0) {
                isValid = false;
                this.errorMessages.push('Fragrance One Sheet PDF is required.');
            }

            // check for PDF Filename
            if (this.currentExtendedFragrance.oneSheetPdfFilename == null || this.currentExtendedFragrance.oneSheetPdfFilename.length == 0) {
                isValid = false;
                this.errorMessages.push('Fragrance One Sheet PDF is required.');
            }
        }
        if (this.isEnforceAdjectivesAndColors) {
            // check for at least 3 colors
            if (this.currentExtendedFragrance.colors == null || this.currentExtendedFragrance.colors.length < 3 || this.currentExtendedFragrance.colors.length > 8) {
                isValid = false;
                this.errorMessages.push('At least 3 Fragrance Colors are required.');
            }
            // check for at least 3 adjectives with weight values
            // upper limit removed
            if (this.currentExtendedFragrance.adjectives == null || this.currentExtendedFragrance.adjectives.length < 1) {
                isValid = false;
                this.errorMessages.push('At least one Fragrance Adjective is required.');
            }
        }
        if (this.currentExtendedFragrance.publishState == null || this.currentExtendedFragrance.publishState.length == 0) {
            isValid = false;
            this.errorMessages.push('Publish State is required.');
        }
        //console.log('isValid? ' + isValid + '; errors: ' + this.errorMessages.join(','));

        if (isValid == true) {
            console.log('create new fragrance extended...');
            this.isSaving = true;
            await this.$store.dispatch('fragrancesExtendedModule/addFragranceExtended', this.currentExtendedFragrance).then((response) => {
                console.log('New ID: ' + response);
                // add saved success message
                this.showSaveSuccess = true;
                this.isSaving = false;
                this.$router.push('/fragrances');
            });
        } else {
            // show error message
            this.showInvalidForm = true;
        }
    },
    onCloseErrorMessageModal() {
        this.showInvalidForm = false;
        this.errorMessages = [];
    }
  }
}

</script>
