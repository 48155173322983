<template>
  <section class="container col-md-3 col-md-offset-3 col-sm-6">
    <h1>The Virtual Scent Expert</h1>
    <div class="my-4">Welcome to Prolitec’s fragrance recommendation tool – 
        here to help you navigate the exciting, wide world of scent options. 
        Enter a project name and click below to begin.</div>
    <div v-if="currentProject != null">
        <div class="row">
            <div class="col">
                <label for="inputProjectName">Client/Project</label>
                <input type="text" id="inputProjectName" class="form-control" v-model="currentProject.name" />
            </div>
        </div>
        <div class="row my-3">
            <div class="col">
                <button class="btn btn-primary" @click="validateAndSaveProject">Begin Quiz</button>
                <p v-if="this.isValidForm == false" class="text-danger mt-2">{{ currentErrorMsg }}</p>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Home',
    components: {
    },
    data() {
        return {
            currentProject: {},
            currentExtendedUser: {},
            currentUserProvider: {},
            isProjectExists: false,
            currentErrorMsg: '',
            isValidForm: true
        }
    },
    mounted() {
        console.log(this.account.username);
        this.currentExtendedUser = this.getUserByEmailAddress(this.account.username);
        console.log(this.currentExtendedUser);
        this.currentUserProvider = this.providerDetail(this.currentExtendedUser.provider);
        this.currentProject = {
            salesperson: this.currentExtendedUser.email,
            provider: this.currentUserProvider.name,
            name: '',
            createDate: null
        };

        if (this.quizResults == null || this.quizResults.length < 1) {
            this.$store.dispatch('quizResultsModule/fetchQuizResults').then(()=> {
                console.log('Quiz Results fetched...');
            });
        }
    },
    computed: {
    ...mapGetters({
            quizResults: 'quizResultsModule/getQuizResults',
            getUserByEmailAddress: 'usersModule/getUserByEmailAddress',
            isSignedInUser: 'isSignedInUser',
            account: 'getCurrentUser',
            providers: "providersModule/getProviders",
            providerDetail: 'providersModule/getProviderById',
        })
    },
    methods: {
        validateAndSaveProject() {
            console.log('Start validation...');
            console.log(this.currentProject);
            var _isValidForm = true;
            var _errorMsg = 'All fields are required.';
            if (this.currentProject == null) {
                _errorMsg += " Error code: 53808";
                _isValidForm = false;
            }
            if (this.currentProject.name == null || this.currentProject.name.length < 1) {
                _isValidForm = false;
                _errorMsg += " Error code: 53809";
            } else {
                let currentResultWithProjectName = this.quizResults.find(result => result.project != null && result.project.name != null && result.project.name == this.currentProject.name);
                if (currentResultWithProjectName != null) {
                    _errorMsg = 'That client/project has already been used.'
                    _isValidForm = false;
                }
            }
            if (this.currentProject.salesperson == null) {
                _errorMsg += " Error code: 53807";
                _isValidForm = false;
            }
            console.log(_isValidForm);

            if (_isValidForm) {
                console.log('Create new Quiz Result and forward to quiz...');
                // create new quiz result
                let newQuizResult = {
                    isQuizStarted: true,
                    isQuizCompleted: false,
                    permalinkUrl: null,
                    quizStartDate: new Date(),
                    quizCompleteDate: null,
                    invite: {},
                    project: {
                        name: this.currentProject.name,
                        salesperson: this.currentProject.salesperson,
                        provider: this.currentProject.provider,
                        createDate: new Date()
                    },
                    questions: [],
                    recommendedFragrances: [],
                    adminRecommendedFragrancesCsvUrl: null,
                    adminSelectedAnswersCsvUrl: null
                }
                this.$store.dispatch('quizResultsModule/addQuizResult', newQuizResult).then((response) => {
                    // get new QuizResult id and redirect
                    let newQuizResultId = response.id;
                    console.log('Quiz Result ID: ' + newQuizResultId);
                    this.$router.push('/quiz/' + newQuizResultId);
                });
            } else {
                this.currentErrorMsg = _errorMsg;
                this.isValidForm = false;
            }
        }
    }
}
</script>
