import axios from 'axios';

var axiosVseFileUploadConfig = {
    headers: {
        'Content-Type': 'multipart/form-data',
        'x-functions-key':'zo558x8R6-MFcs0c0TDzFfQ1sTGOk8x8weE5lCDdbe6NAzFuimpfGA==',
        'Referrer-Policy': 'origin-when-cross-origin'
    }
};
var axiosVseFileDownloadConfig = {
    headers: {
        'Content-Type': 'multipart/form-data',
        'x-functions-key':'zQvcYr6MTkr2mpohm2roerXWJILfZnwmCh4wkdUwwAF5AzFuXFlYuA==',
        'Referrer-Policy': 'origin-when-cross-origin'
    }
};
var azureVseFileUploadUrl = 'https://prolitecdataservices.azurewebsites.net/api/vseFileUpload?filename=';
var azureVseFileDownloadUrl = 'https://prolitecdataservices.azurewebsites.net/api/vseFileDownload?filename=';


export async function uploadFile(fileObject) {
    console.log('API -> uploadFile...');
    console.log(fileObject.filename);
    
    return await axios.post(azureVseFileUploadUrl + fileObject.filename, fileObject.file, axiosVseFileUploadConfig)
    .then((response) => {
        //setFragranceItems(state, response.data)
        console.log('Upload: ' + response.status);
        //commit('HERO_IMAGE_FILE_UPLOAD_SUCCESS', response.data);
        return axios.get(azureVseFileDownloadUrl + fileObject.filename, axiosVseFileDownloadConfig);
    }).then((response) => {
        console.log(response.data);
        console.log('Download Url: ' + response.data.url);
        return response.data.url;
    })
    .catch(error => {
            console.error('API Error.',error);
            //context.commit('FILE_UPLOAD_FAILURE', false);
    });
    //new Error(response.message || 'Failed to fetch requests.'));
}

// async function downloadFile(fileObject) {
//     console.log('API -> downloadFile...');
//     console.log(fileObject.filename);

//     try {
//         axios.get(azureVseFileDownloadUrl + fileObject.filename, axiosVseFileDownloadConfig)
//         .then((response) => {
//           if (response.status == 200) {
//             //setFragranceItems(state, response.data)
//             console.log(response.data);
//             //context.commit('FILE_DOWNLOAD_SUCCESS', response.data);
//           }
//         })
//         .catch(error => {
//                 console.error('API Error.',error);
//                 //context.commit('FILE_DOWNLOAD_FAILURE', false);
//         });
//         //new Error(response.message || 'Failed to fetch requests.'));
//     } finally {
//         //commit('contentLoading', false);
//     }
// }