import axios from 'axios'; // at the start of your <script> tag, before you "export default ..."

export default {
  namespaced: true,
  state() {
    return {
        axiosFragranceExtendedListConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'YzI412hwK9A00FygfoyE24qGFwOfn8FCizqvv1YZN0bnbx3oA7peqQ==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        axiosAddVseFragranceExtendedConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'sMaZkcTVwpNGwi7dL2qNyvkLM/FTOHKdxHG1vSPOTPRNGxUjcG39Ww==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        axiosUpdateVseFragranceExtendedConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'pi8iqjOgF31gk/gMYeEo8su26PUlx5beaZqcbow9vkrmhfMUl/tySQ==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        axiosVseFileUploadConfig: {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-functions-key':'zo558x8R6-MFcs0c0TDzFfQ1sTGOk8x8weE5lCDdbe6NAzFuimpfGA==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        axiosVseFileDownloadConfig: {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'zQvcYr6MTkr2mpohm2roerXWJILfZnwmCh4wkdUwwAF5AzFuXFlYuA==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }
        },
        fragrancesExtendedList: [],
        publishStateOptions: [ 'Draft', 'Published'],
        families: ['Aromatic', 'Citrus', 'Floral', 'Fruity', 'Gourmand', 'Green', 'Amber', 'Ozonic', 'Watery', 'Woody'],
        collections: ['Core', 'Designer', 'Holidays', 'Prestige'],
        currentExtendedFragrance: null,
        uploadHeroImageSuccess: false,
        uploadOneSheetPdfSuccess: false,
        downloadHeroImageSuccess: false,
        downloadOneSheetPdfSuccess: false,
        uploadHeroImageUrl: null,
        uploadOneSheetPdfUrl: null,
        //downloadHeroImageUrl: null,
        //downloadOneSheetUrl: null,
        isFragranceListCachable: true
    };
  },
  getters: {
        getFragrancesExtended(state) {
            console.log('Accessing getExtendedFragrances getter...');
            if (state.fragrancesExtendedList != null && state.fragrancesExtendedList.length > 0) {
                console.log('State detected in getter. Length: ' + state.fragrancesExtendedList.length);
            }
            return state.fragrancesExtendedList;
        },
        getPublishedFragrancesExtended(state) {
            console.log('Accessing getExtendedFragrances getter...');
            if (state.fragrancesExtendedList != null && state.fragrancesExtendedList.length > 0) {
                console.log('State detected in getter. Length: ' + state.fragrancesExtendedList.length);
            }
            return state.fragrancesExtendedList.filter(fragrance => fragrance.publishState == 'Published');
        },
      getFragranceExtendedById: (state) => (id) => {
        //console.log(state);
        //console.log(id);
          if (null != state.fragrancesExtendedList) {
            return state.fragrancesExtendedList.find(fragranceExtended => fragranceExtended.id === id);
          } else {
            return null;
          }   
      },
      getCurrentExtendedFragrance(state) {
        return state.currentExtendedFragrance;
      },
      count: (state) => {
        if (state.fragrancesExtendedList == null) {
            return 0;
        } else {
            return state.fragrancesExtendedList.length;
        }        
      },
      publishedCount: (state) => {
        if (state.fragrancesExtendedList == null) {
            return 0;
        } else {
            return state.fragrancesExtendedList.filter(fe => fe.publishState == 'Published').length;
        }        
      },
      getUploadedHeroImageUrl(state) {
        return state.uploadHeroImageUrl;
      },
      getUploadedOneSheetPdfUrl(state) {
        return state.uploadOneSheetPdfUrl;
      },
      getDownloadHeroImageUrl(state) {
        return state.downloadHeroImageUrl;
      },
      getDownloadOneSheetPdfUrl(state) {
        return state.downloadOneSheetUrl;
      },
      getCollections(state) {
        return state.collections;
      },
      getFamilies(state) {
        return state.families;
      },
      getPublishedStateOptions(state) {
        return state.publishStateOptions;
      }
  },
  mutations: {
    SET_FRAGRANCES_EXTENDED(state, fragrancesExtended) {
        console.log('Set fragranceExtended mutation...');
        console.log(fragrancesExtended);
        state.fragrancesExtendedList = fragrancesExtended;
    },
    SET_CURRENT_FRAGANCE_EXTENDED(state, currentFragranceExtended) {
        console.log(currentFragranceExtended);
        state.currentExtendedFragrance = currentFragranceExtended;
    },
    ADD_CURRENT_FRAGRANCE_EXTENDED_COLOR(state, color) {
        console.log('add color to store object...' + color);
        state.currentExtendedFragrance.colors.push(color);
    },
    UPDATE_CURRENT_FRAGRANCE_EXTENDED_COLOR(state, updatedColor) {
        console.log('update color at index[' + updatedColor.index + ']...' + updatedColor.color);
        if (updatedColor.index >= 0 && updatedColor.index < state.currentExtendedFragrance.colors.length) {
            state.currentExtendedFragrance.colors[updatedColor.index] = updatedColor.color;
        } 
    },
    REMOVE_CURRENT_FRAGRANCE_EXTENDED_COLOR(state, index) {
        console.log('Removing color swatch...' + index);
        console.log(index);
        if (index >= 0 && index < state.currentExtendedFragrance.colors.length) {
            state.currentExtendedFragrance.colors.splice(index, 1);
        } 
    },
    ADD_CURRENT_FRAGRANCE_EXTENDED_ADJECTIVE(state, adjective) {
        console.log('add adjective to store object...' + adjective);
        state.currentExtendedFragrance.adjectives.push(adjective);
    },
    UPDATE_CURRENT_FRAGRANCE_EXTENDED_ADJECTIVE(state, updatedAdjective) {
        console.log('update adjective...' + updatedAdjective.name + '; weight: ' + updatedAdjective.weight);
        const index = state.currentExtendedFragrance.adjectives.findIndex(x => x.id === updatedAdjective.id);
        if (index !== undefined) {
            state.currentExtendedFragrance.adjectives[index] = updatedAdjective;
        } 
    },
    REMOVE_CURRENT_FRAGRANCE_EXTENDED_ADJECTIVE(state, index) {
        if (index >= 0 && index < state.currentExtendedFragrance.adjectives.length) {
            state.currentExtendedFragrance.adjectives.splice(index, 1);
        } 
    },
    ADD_FRAGRANCE_EXTENDED(state, fragranceExtended) {
        console.log('Add fragranceExtended mutation...');
        console.log(fragranceExtended);
        state.fragrancesExtendedList.push(fragranceExtended); 
    },
    UPDATE_FRAGRANCE_EXTENDED(state, fragranceExtended) {
        console.log('Update adjective mutation...');
        console.log(fragranceExtended);
        const index = state.fragrancesExtendedList.findIndex(x => x.id === fragranceExtended.id);
        if (index !== undefined) {
            state.fragrancesExtendedList[index] = fragranceExtended;
        }
    },
    DELETE_FRAGRANCE_EXTENDED(state, fragranceExtendedId) {
        console.log('Delete fragranceExtended mutation...');
        console.log(fragranceExtendedId);
        const index = state.fragrancesExtendedList.findIndex(x => x.id === fragranceExtendedId);
        if (index !== undefined) {
            state.fragrancesExtendedList.splice(index, 1);
        } 
    },
    HERO_IMAGE_FILE_UPLOAD_SUCCESS(state, fileUploadResponse) {
        // console.log('Hero Image Upload Success...');
        // console.log(fileUploadResponse);
        // console.log(fileUploadResponse.json);
        var json = JSON.parse(fileUploadResponse.json);
        console.log(json.url);
        state.uploadHeroImageSuccess = true;
        state.uploadHeroImageUrl = json.url;
    },
    HERO_IMAGE_FILE_UPLOAD_FAILURE(state) {
        console.log('Hero Image Upload Failure...');
        state.uploadHeroImageSuccess = false;
        state.uploadHeroImageUrl = null;
    },
    ONE_SHEET_PDF_FILE_UPLOAD_SUCCESS(state, fileUploadResponse) {
        // console.log('Hero Image Upload Success...');
        // console.log(fileUploadResponse);
        // console.log(fileUploadResponse.json);
        var json = JSON.parse(fileUploadResponse.json);
        console.log(json.url);
        state.uploadOneSheetPdfSuccess = true;
        state.uploadOneSheetPdfUrl = json.url;
    },
    ONE_SHEET_PDF_FILE_UPLOAD_FAILURE(state) {
        console.log('One Sheet Pdf Upload Failure...');
        state.uploadOneSheetPdfSuccess = false;
        state.uploadOneSheetPdfUrl = null;
    },
    HERO_IMAGE_FILE_DOWNLOAD_SUCCESS(state, fileDownloadResponse) {
        // console.log('Hero Image Upload Success...');
        // console.log(fileUploadResponse);
        // console.log(fileUploadResponse.json);
        //var json = JSON.parse(fileDownloadResponse.json);
        var json = fileDownloadResponse;
        console.log(json.url);
        state.downloadHeroImageSuccess = true;
        state.currentExtendedFragrance.heroImageUrl = json.url;
        //state.downloadHeroImageUrl = json.url;
    },
    HERO_IMAGE_FILE_DOWNLOAD_FAILURE(state) {
        console.log('Hero Image Upload Failure...');
        state.downloadHeroImageSuccess = false;
        state.currentExtendedFragrance.heroImageUrl = null;
    },
    ONE_SHEET_PDF_FILE_DOWNLOAD_SUCCESS(state, fileDownloadResponse) {
        // console.log('Hero Image Upload Success...');
        // console.log(fileUploadResponse);
        // console.log(fileUploadResponse.json);
        //var json = JSON.parse(fileDownloadResponse.json);
        var json = fileDownloadResponse;
        console.log('SET PDF Download URL...');
        console.log(json.url);
        state.downloadOneSheetPdfSuccess = true;
        state.currentExtendedFragrance.oneSheetPdfUrl = json.url;
    },
    ONE_SHEET_PDF_FILE_DOWNLOAD_FAILURE(state) {
        console.log('One Sheet Pdf Upload Failure...');
        state.downloadOneSheetPdfSuccess = false;
        state.downloadOneSheetPdfUrl = null;
    },
  },
  actions: {
    async fetchFragrancesExtended({ commit, state }) {
        try {
            console.log('Fetch extended fragrances...');
            return await axios.get('https://prolitecdataservices.azurewebsites.net/api/vseListFragrancesExtended',state.axiosFragranceExtendedListConfig)
            .then((response) => {
                if (response.status == 200) {
                //setFragranceItems(state, response.data)
                console.log(response.data);
                commit('SET_FRAGRANCES_EXTENDED', response.data.items);
                return response.data.items;
                }
            })
            .catch(error => {
                console.error('API Error.',error);
                });
                //new Error(response.message || 'Failed to fetch requests.'));
            } finally {
            //commit('contentLoading', false);
            }
    },
    async addFragranceExtended({ commit, state }, newFragranceExtended) {
        try {
            //commit('contentLoading', true);
            var postVseFragranceExtendedData = {
                'name': newFragranceExtended.name,
                'sku': newFragranceExtended.sku,
                'shortDescription': newFragranceExtended.shortDescription,
                'longDescription': newFragranceExtended.longDescription,
                'family': newFragranceExtended.family,
                'collection': newFragranceExtended.collection,
                'publishState': newFragranceExtended.publishState,
                'isOdorNeutralizer': newFragranceExtended.isOdorNeutralizer,
                'detectionLevel': newFragranceExtended.detectionLevel,
                'heroImageUrl': newFragranceExtended.heroImageUrl,
                'oneSheetPdfUrl': newFragranceExtended.oneSheetPdfUrl,
                'oneSheetPdfFilename': newFragranceExtended.oneSheetPdfFilename,
                'adjectives': newFragranceExtended.adjectives,
                'colors': newFragranceExtended.colors
              };
            await axios.post('https://prolitecdataservices.azurewebsites.net/api/vseCreateFragranceExtended', postVseFragranceExtendedData, state.axiosAddVseFragranceExtendedConfig)
            .then((response) => {
              if (response.status == 200) {
                //setFragranceItems(state, response.data)
                console.log(response.data);
                newFragranceExtended.id = response.data.id;
                commit('ADD_FRAGRANCE_EXTENDED', newFragranceExtended);
              }
            })
            .catch(error => {
                    console.error('API Error.',error);
            });
            //new Error(response.message || 'Failed to fetch requests.'));
        } finally {
            //commit('contentLoading', false);
        }
    },
    async updateFragranceExtended({ commit, state }, fragranceExtended) {
        try {
            //commit('contentLoading', true);
            var postVseFragranceExtendedData = {
                'id': fragranceExtended.id,
                'name': fragranceExtended.name,
                'sku': fragranceExtended.sku,
                'shortDescription': fragranceExtended.shortDescription,
                'longDescription': fragranceExtended.longDescription,
                'family': fragranceExtended.family,
                'collection': fragranceExtended.collection,
                'isOdorNeutralizer': fragranceExtended.isOdorNeutralizer,
                'publishState': fragranceExtended.publishState,
                'detectionLevel': fragranceExtended.detectionLevel,
                'heroImageUrl': fragranceExtended.heroImageUrl,
                'oneSheetPdfUrl': fragranceExtended.oneSheetPdfUrl,
                'oneSheetPdfFilename': fragranceExtended.oneSheetPdfFilename,
                'adjectives': fragranceExtended.adjectives,
                'colors': fragranceExtended.colors
              };
            await axios.post('https://prolitecdataservices.azurewebsites.net/api/vseUpdateFragranceExtended', postVseFragranceExtendedData, state.axiosUpdateVseFragranceExtendedConfig)
            .then((response) => {
              if (response.status == 200) {
                console.log(response.data);
                commit('UPDATE_FRAGRANCE_EXTENDED', fragranceExtended);
              }
            })
        .catch(error => {
            console.error('API Error.',error);
            });
              //new Error(response.message || 'Failed to fetch requests.'));
        } finally {
            //commit('contentLoading', false);
        }
    },
    async deleteFragranceExtended({ commit }, fragranceExtendedId) {
        console.log(fragranceExtendedId);
        try {
            await axios.delete('https://prolitecdataservices.azurewebsites.net/api/vseDeleteFragranceExtended', { headers: {
                'Content-Type': 'application/json',
                'x-functions-key':'BOjlSwlZ2bq7FI86YSRNaMpEUGtOIFjOW1cCpCrApa6rV4KaBCQIOQ==',
                'Referrer-Policy': 'origin-when-cross-origin'
            }, data: { id: fragranceExtendedId } })
            .then((response) => {
              if (response.status == 200) {
                console.log(response.data);
                commit('DELETE_FRAGRANCE_EXTENDED', fragranceExtendedId);
              }
            })
        .catch(error => {
            console.error('API Error.',error);
            });
              //new Error(response.message || 'Failed to fetch requests.'));
        } finally {
            //commit('contentLoading', false);
        }
    },
    async uploadHeroImage(context, payloadObject ) {
        console.log('API -> uploadHeroImage...');
        console.log(payloadObject.filename);
        
        try {
            await axios.post('https://prolitecdataservices.azurewebsites.net/api/vseFileUpload?filename=' + payloadObject.filename, payloadObject.file, context.state.axiosVseFileUploadConfig)
            .then((response) => {
              if (response.status == 200) {
                //setFragranceItems(state, response.data)
                console.log(response.data);
                //commit('HERO_IMAGE_FILE_UPLOAD_SUCCESS', response.data);
                return response.data;
              }
            }).then((data) => {
                var json = JSON.parse(data.json);
                payloadObject.url = json.url;
                console.log(json.url);
                context.dispatch('getHeroImageDownloadFilename', payloadObject, context);
            })
            .catch(error => {
                    console.error('API Error.',error);
                    context.commit('HERO_IMAGE_FILE_UPLOAD_FAILURE', false);
            });
            //new Error(response.message || 'Failed to fetch requests.'));
        } finally {
            //commit('contentLoading', false);
        }
    },
    async uploadOneSheetPdf(context, payloadObject ) {
        console.log('API -> uploadOneSheetPdf...');
        console.log(payloadObject.filename);
        
        try {
            await axios.post('https://prolitecdataservices.azurewebsites.net/api/vseFileUpload?filename=' + payloadObject.filename, payloadObject.file, context.state.axiosVseFileUploadConfig)
            .then((response) => {
              if (response.status == 200) {
                //setFragranceItems(state, response.data)
                console.log(response.data);
                //context.commit('ONE_SHEET_PDF_FILE_UPLOAD_SUCCESS', response.data);
              }
            }).then(() => {
                // var json = JSON.parse(data.json);
                // payloadObject.url = json.url;
                // console.log(json.url);
                context.dispatch('getOneSheetPdfDownloadFilename', payloadObject, context);
            })
            .catch(error => {
                    console.error('API Error.',error);
                    context.commit('ONE_SHEET_PDF_FILE_UPLOAD_FAILURE', false);
            });
            //new Error(response.message || 'Failed to fetch requests.'));
        } finally {
            //commit('contentLoading', false);
        }
    },
    async getHeroImageDownloadFilename(context, payloadObject ) {
        console.log('API -> downloadFile...');
        console.log(payloadObject.filename);

        try {
            axios.get('https://prolitecdataservices.azurewebsites.net/api/vseFileDownload?filename=' + payloadObject.filename, context.state.axiosVseFileDownloadConfig)
            .then((response) => {
              if (response.status == 200) {
                //setFragranceItems(state, response.data)
                console.log(response.data);
                context.commit('HERO_IMAGE_FILE_DOWNLOAD_SUCCESS', response.data);
              }
            })
            .catch(error => {
                    console.error('API Error.',error);
                    context.commit('HERO_IMAGE_FILE_DOWNLOAD_FAILURE', false);
            });
            //new Error(response.message || 'Failed to fetch requests.'));
        } finally {
            //commit('contentLoading', false);
        }
    },
    async getOneSheetPdfDownloadFilename(context, payloadObject ) {
        console.log('API -> downloadFile...');
        console.log(payloadObject.filename);
        
        try {
            axios.get('https://prolitecdataservices.azurewebsites.net/api/vseFileDownload?filename=' + payloadObject.filename, context.state.axiosVseFileDownloadConfig)
            .then((response) => {
              if (response.status == 200) {
                //setFragranceItems(state, response.data)
                console.log('Download PDF URL...');
                console.log(response.data);
                context.commit('ONE_SHEET_PDF_FILE_DOWNLOAD_SUCCESS', response.data);
              }
            })
            .catch(error => {
                    console.error('API Error.',error);
                    context.commit('ONE_SHEET_PDF_FILE_DOWNLOAD_FAILURE', false);
            });
            //new Error(response.message || 'Failed to fetch requests.'));
        } finally {
            //commit('contentLoading', false);
        }
    },
  }
};