<template>
    <section>
        <!-- ERROR MESSAGE MODAL -->
        <div class="modal fade show" id="myModal" style="display: block">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" v-text="errorTitle == null || errorTitle == '' || errorTitle == undefined ? 'An Error Occurred' : errorTitle"></h4>
                        <button type="button" class="btn-close" @click="closeErrorMessageModal" data-dismiss="modal" aria-label="Close">
                            <span class="visually-hidden" aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <li v-for="error in errorMessages" class="text-danger" v-bind:key="error">{{ error }}</li>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="closeErrorMessageModal" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-backdrop fade show"></div>
        <!-- END: ERROR MESSAGE MODAL -->
    </section>
</template>
<script>
export default {
    name: "ErrorMessageModal",
    components: {},
    data() {
        return {
        };
    },
    props: {
        errorMessages: {
            type: Array,
        },
        errorTitle: {
            type: String,
        }
    },
    computed: {
    },
    methods: {
        closeErrorMessageModal() {
            console.log('componentClose');
            this.$emit('closeErrorMessageModal');
        },
    },
};
</script>
