<template>
  <div class="container-fluid" :class="this.currentTheme">
    <HeaderBar :theme="this.currentTheme" />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import HeaderBar from '@/components/header-bar';
import Footer from '@/components/footer';
import store from '@/store';
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  store,
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
        currentTheme: 'uiModule/getCurrentTheme',
    })
  },
  methods: {
  },
  components: { HeaderBar, Footer },
};
</script>

<style>


</style>
