<!-- ADJECTIVE ADMIN LIST -->
<!-- properties: mode (display/edit), id, name, includeWeight?, weight, active, selected, disabled, sort order, actions: add/remove/changeOrder -->
<template>
    <!-- Adjective List -->
    <div id="AdjectiveAdminList" class="row my-5">
        <h2>Adjectives ({{ adjectiveList.length}})</h2>
        <ul class="list-unstyled card-columns">
            <li v-for="(adjective, index) in adjectiveList" :key="adjective.id" class="card p-3 mb-2" :class="isSelectedAdjective(adjective.id) ? 'active' : ''">
                <!-- Weight column (optional) -->
                <!-- Name of Adjective -->
                <div @click="addToSelectedList(adjective, index)" class="link link-primary d-flex justify-content-between align-items-center">
                    <div>
                        <span class="ms-2">{{ adjective.name }}</span>
                    </div>
                    <div>
                        <span v-if="!isSelectedAdjective(adjective.id)" class="me-2 text-primary"><BIconPlusLg /></span>
                    </div>
                </div>
                <!--<router-link :to="{ name: 'AdjectiveDetail', params: { adjectiveId: adjective.id }}" class="link link-primary">{{ adjective.name }}</router-link>-->
                <!-- Action column (optional) -->
            </li>
        </ul>
    </div>
</template>

<script>
import {mapGetters } from 'vuex';

export default {
  name: 'AdjectiveAdminList',
  components: { },
  data() {
    return {
        //adjectiveItems: [],
        isSortable: false,
        isDraggable: false,
    }
  },
  props: ['selectedAdjectives'],
  computed: {
    ...mapGetters({
        adjectiveList: 'adjectivesModule/getAdjectives',
        count: 'adjectivesModule/count'
    },),
    // selectedAdjectives() {
    //     return this.$store.getters['fragrancesExtendedModule/getCurrentExtendedFragrance'].adjectives;
    // },
  },
    mounted() {
    // get from API
    if (this.$store.getters.getAdjectives == null) {
        console.log('Need adjectives...call load action from mounted.');
        this.load();
    }
  },
  methods: {
    //...mapActions('')
    async load() {
        console.log('List Adjectives - load()');
        await this.$store.dispatch("adjectivesModule/fetchAdjectives");
        console.log('List Adjectives - END load()');
    },
    addToSelectedList(adjective) {
        this.$emit('addItem', adjective);
        // const index = this.selectedAdjectives.findIndex(x => x.id === adjective.id);
        // console.log('index: ' + index);
        // if (index == -1) {
        //     //this.selectedAdjectives.splice(index, 1);
            
        // }
        
        // add active class to item
    },
    isSelectedAdjective(adjectiveId) {
        const index = this.selectedAdjectives.findIndex(x => x.id === adjectiveId);
        return index > -1 ? true : false;
    }
  }

};
</script>
